import React from 'react'

import Job from './Job'

// import Header from '../../Components/Header/Header'
// import Footer from '../../Components/Footer/Footer'
function Careers() {
  return (
    <div>
        {/* <Header/> */}
        <Job/>
       {/* <Footer/> */}
    </div>
  )
}

export default Careers