import React from 'react'
// import Header from '../../Components/Header/Header'
import VideoComponent from './VideoComponent'
import JoinToday from '../../Components/Join Today/JoinToday'
// import Footer from '../../Components/Footer/Footer'
// import Feature from '../../Components/Feature/Feature'
function UiUxdesign() {
  return (
    <div>
        {/* <Header/> */}
        {/* <h1></h1> */}
      <VideoComponent/>
        
        {/* <Footer/> */}
    </div>
  )
}

export default UiUxdesign